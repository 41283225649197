import SvgIcon, { SvgIconProps } from "./SvgIcon";

const EmojiIcon: React.FC<SvgIconProps> = ({
  variant = "contained",
  ...props
}) => {
  return (
    <SvgIcon {...props}>
      {variant == "contained" ? (
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22ZM9.5 11C10.3284 11 11 10.3284 11 9.5C11 8.67157 10.3284 8 9.5 8C8.67157 8 8 8.67157 8 9.5C8 10.3284 8.67157 11 9.5 11ZM16 9.5C16 10.3284 15.3284 11 14.5 11C13.6716 11 13 10.3284 13 9.5C13 8.67157 13.6716 8 14.5 8C15.3284 8 16 8.67157 16 9.5ZM17.4473 12.1057C17.9413 12.3526 18.1416 12.9532 17.8947 13.4473C16.7235 15.7908 14.7078 17.0002 12.0002 17.0002C9.29261 17.0002 7.27696 15.7908 6.10571 13.4473C5.85881 12.9532 6.05914 12.3526 6.55317 12.1057C7.04719 11.8588 7.64783 12.0591 7.89473 12.5532C8.72257 14.2096 10.0403 15.0002 12.0002 15.0002C13.9602 15.0002 15.2779 14.2096 16.1057 12.5532C16.3526 12.0591 16.9532 11.8588 17.4473 12.1057Z"
        />
      ) : (
        <path d="M12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2ZM12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4ZM17.4471 12.1055C17.9411 12.3524 18.1414 12.953 17.8945 13.4471C16.7233 15.7906 14.7076 17 12 17C9.29239 17 7.27674 15.7906 6.10549 13.4471C5.85859 12.953 6.05893 12.3524 6.55295 12.1055C7.04697 11.8586 7.64761 12.0589 7.89451 12.5529C8.72235 14.2094 10.04 15 12 15C13.96 15 15.2776 14.2094 16.1055 12.5529C16.3524 12.0589 16.953 11.8586 17.4471 12.1055ZM9.5 8C10.3284 8 11 8.67157 11 9.5C11 10.3284 10.3284 11 9.5 11C8.67157 11 8 10.3284 8 9.5C8 8.67157 8.67157 8 9.5 8ZM14.5 8C15.3284 8 16 8.67157 16 9.5C16 10.3284 15.3284 11 14.5 11C13.6716 11 13 10.3284 13 9.5C13 8.67157 13.6716 8 14.5 8Z" />
      )}
    </SvgIcon>
  );
};

export default EmojiIcon;
