import {
  ContactDetailsFormType,
  EmergencyContactFormType,
  GeneralInfoFormType,
  GuardianDetailsFormType,
  PassportFormType,
  PersonalDetailsFormType,
  ProfileInfoFormType,
  WorkingHolidayVisaFormType,
  DepartureDateFormType,
  Status,
} from "@/components/PersonalDetailsForms";
import { usePersonalDetailsQuery } from "@/fetch/profiles";
import {
  useUpdateConvictionsMutation,
  useUpdateHealthMutation,
  useUpdatePassportMutation,
  useUploadTravellerProfilePicture,
} from "@/fetch/profiles/traveller";
import {
  useCreateContactMethodMutation,
  useUpdateContactMethodMutation,
  useCreateEmergencyContactMutation,
  useUpdateEmergencyContactMutation,
  useCreateEmergencyContactMethodMutation,
  useUpdateEmergencyContactMethodMutation,
  useCreateAddressMutation,
  useUpdateAddressMutation,
  useCreateTravellerInfoMutation,
  useUpdateTravellerInfoMutation,
  useDeleteContactMethodMutation,
  useDeleteEmergencyContactMethodMutation,
} from "@/fetch/traveller";
import {
  useCreateVisaMutation,
  useVisaQuery,
  useUpdateSignificantDateMutation,
  SignificantDateTypes,
} from "@/fetch/trip";
import { useEffect } from "react";
import useFormatContactMethod from "./useFormatContactMethod";

const usePersonalDetailsFormSubscription = () => {
  const {
    mutateAsync: createEmergencyContact,
    isLoading: isCreatingEmergencyContact,
    cancel: cancelCreateEmergencyContact,
  } = useCreateEmergencyContactMutation();
  const {
    mutateAsync: updateEmergencyContact,
    isLoading: isUpdatingEmergencyContact,
    cancel: cancelUpdateEmergencyContact,
  } = useUpdateEmergencyContactMutation();
  const {
    mutateAsync: createEmergencyContactMethod,
    isLoading: isCreatingEmergencyContactMethod,
    cancel: cancelCreateEmergencyContactMethod,
  } = useCreateEmergencyContactMethodMutation();
  const {
    mutateAsync: updateEmergencyContactMethod,
    isLoading: isUpdatingEmergencyContactMethod,
    cancel: cancelUpdateEmergencyContactMethod,
  } = useUpdateEmergencyContactMethodMutation();
  const {
    mutateAsync: createTravellerInfo,
    isLoading: isCreatingTravellerInfo,
    cancel: cancelCreateTravellerInfo,
  } = useCreateTravellerInfoMutation();
  const {
    mutateAsync: updateTravellerInfo,
    isLoading: isUpdatingTravellerInfo,
    cancel: cancelUpdateTravellerInfo,
  } = useUpdateTravellerInfoMutation();
  const {
    mutateAsync: createAddress,
    isLoading: isCreatingAddress,
    cancel: cancelCreateAddress,
  } = useCreateAddressMutation();
  const {
    mutateAsync: updateAddress,
    isLoading: isUpdatingAddress,
    cancel: cancelUpdateAddress,
  } = useUpdateAddressMutation();
  const {
    mutateAsync: updatePassport,
    isLoading: isUpdatingPassport,
    cancel: cancelUpdatePassport,
  } = useUpdatePassportMutation();
  const {
    mutateAsync: updateHealth,
    isLoading: isUpdatingHealth,
    cancel: cancelUpdateHealth,
  } = useUpdateHealthMutation();
  const {
    mutateAsync: updateConvictions,
    isLoading: isUpdatingConvictions,
    cancel: cancelUpdateConvictions,
  } = useUpdateConvictionsMutation();
  const {
    mutateAsync: deleteContactMethod,
    isLoading: isDeletingContactMethod,
    cancel: cancelDeleteContactMethod,
  } = useDeleteContactMethodMutation();
  const {
    mutateAsync: deleteEmergencyContactMethod,
    isLoading: isDeletingEmergencyContactMethod,
    cancel: cancelDeleteEMergencyContactMethod,
  } = useDeleteEmergencyContactMethodMutation();
  const {
    uploadProfilePicture,
    isPreparing,
    isUploading,
    cancelUploadRequest,
    progress,
  } = useUploadTravellerProfilePicture();

  const {
    mutateAsync: createContactMethod,
    isLoading: isCreatingContactMethod,
    cancel: cancelCreateContactMethod,
  } = useCreateContactMethodMutation();
  const {
    mutateAsync: updateContactMethod,
    isLoading: isUpdatingContactMethod,
    cancel: cancelUpdateContactMethod,
  } = useUpdateContactMethodMutation();

  const { data: personalDetailsData } = usePersonalDetailsQuery();
  const { data: visaData } = useVisaQuery();

  const {
    formatContactMethodToGateway,
    formatEmergencyContactMethodToGateway,
    formatGuardianContactMethodToGateway,
  } = useFormatContactMethod();

  const {
    mutateAsync: createVisa,
    isLoading: isCreatingVisa,
    cancel: cancelCreateVisa,
  } = useCreateVisaMutation();

  const {
    mutateAsync: updateSignificantDate,
    isLoading: isUpdatingSignificantDate,
    cancel: cancelUpdateSignificantDate,
  } = useUpdateSignificantDateMutation();

  const emergencyContact = personalDetailsData?.emergencyContacts?.[0];
  const guardianContact = personalDetailsData?.guardianContact?.[0];
  const passport = personalDetailsData?.passport;
  const contactMethods = personalDetailsData?.contactMethods;
  const addresses = personalDetailsData?.addresses;
  const health = personalDetailsData?.health;
  const convictions = personalDetailsData?.convictions;
  const travellerInfo = personalDetailsData?.traveller;

  const workingHolidayVisa = visaData?.result;

  const submitProfileInfoForm = (
    values: ProfileInfoFormType,
    correlationId: string
  ) => {
    const promises = [];

    const travellerInfoData = {
      bio: Boolean(values?.profileInfoBio) ? values.profileInfoBio : null,
      nickname: Boolean(values?.profileInfoNickname)
        ? values.profileInfoNickname
        : null,
    };
    if (travellerInfo?.id) {
      promises.push(
        updateTravellerInfo({
          data: { ...travellerInfoData },
          correlationId: correlationId,
        })
      );
    } else {
      promises.push(
        createTravellerInfo({
          data: { ...travellerInfoData },
          correlationId: correlationId,
        })
      );
    }

    if (values.profileInfoProfilePictureFile) {
      promises.push(
        uploadProfilePicture({ file: values.profileInfoProfilePictureFile })
      );
    }
    return Promise.all(promises);
  };

  const submitGeneralInfoForm = (
    values: GeneralInfoFormType,
    correlationId: string
  ) => {
    const travellerInfoData = {
      first_name: Boolean(values?.generalInfoFirstName)
        ? values.generalInfoFirstName
        : null,
      middle_name: Boolean(values?.generalInfoMiddleName)
        ? values.generalInfoMiddleName
        : null,
      last_name: Boolean(values?.generalInfoLastName)
        ? values.generalInfoLastName
        : null,
      date_of_birth: Boolean(values?.generalInfoDateOfBirth)
        ? values.generalInfoDateOfBirth
        : null,
      gender: Boolean(values?.generalInfoGender)
        ? values.generalInfoGender
        : null,
      nationality: Boolean(values?.generalInfoNationality?.label?.length)
        ? values.generalInfoNationality?.label
        : null,
    };
    if (travellerInfo?.id) {
      return updateTravellerInfo({
        data: { ...travellerInfoData },
        correlationId: correlationId,
      });
    } else {
      return createTravellerInfo({
        data: { ...travellerInfoData },
        correlationId: correlationId,
      });
    }
  };

  const submitGuardianDetailsForm = async (
    values: GuardianDetailsFormType,
    correlationId: string
  ) => {
    const promises = [];
    const guardianDetailsData = {
      guardian: 1,
      full_name: Boolean(values.guardianDetailsName?.length)
        ? values.guardianDetailsName
        : null,
      email: Boolean(values.guardianDetailsEmail?.length)
        ? values.guardianDetailsEmail
        : null,
      relationship: Boolean(values.guardianDetailsRelationSelect?.length)
        ? values.guardianDetailsRelationSelect === "Other"
          ? values.guardianDetailsRelationTextField
          : values.guardianDetailsRelationSelect
        : null,
    };
    // Transform primary and secondary contact methods to relevant records for gateway
    const contactMethods = formatGuardianContactMethodToGateway(
      guardianContact?.contacts || [],
      values
    );

    try {
      if (guardianContact?.contact_id) {
        promises.push(
          updateEmergencyContact({
            data: {
              id: guardianContact.contact_id,
              ...guardianDetailsData,
            },
            correlationId: correlationId,
          })
        );
        contactMethods.forEach((eachContact) => {
          if (eachContact.id) {
            if (eachContact.reference && eachContact.region_code) {
              promises.push(
                updateEmergencyContactMethod({
                  obj: {
                    emergencyContactId: guardianContact.contact_id as number,
                    data: eachContact,
                  },
                  correlationId: correlationId,
                })
              );
            } else {
              promises.push(
                deleteEmergencyContactMethod({
                  contactId: eachContact.id,
                  emergencyContactId: guardianContact.contact_id as number,
                })
              );
            }
          } else {
            promises.push(
              createEmergencyContactMethod({
                obj: {
                  emergencyContactId: guardianContact.contact_id as number,
                  data: eachContact,
                },
                correlationId: correlationId,
              })
            );
          }
        });
      } else {
        const guardianContactResponse = await createEmergencyContact({
          data: guardianDetailsData,
          correlationId: correlationId,
        });
        contactMethods.forEach((eachContact) => {
          if (eachContact.id) {
            promises.push(
              updateEmergencyContactMethod({
                obj: {
                  emergencyContactId: guardianContactResponse.result.id,
                  data: eachContact,
                },
                correlationId: correlationId,
              })
            );
          } else {
            promises.push(
              createEmergencyContactMethod({
                obj: {
                  emergencyContactId: guardianContactResponse.result.id,
                  data: eachContact,
                },
                correlationId: correlationId,
              })
            );
          }
        });
      }
      return Promise.all(promises);
    } catch (error) {
      return Promise.reject(error);
    }
  };

  const submitEmergencyContactForm = async (
    values: EmergencyContactFormType,
    correlationId: string
  ) => {
    const promises = [];
    const emergencyContactData = {
      guardian: 0,
      full_name: Boolean(values.emergencyContactName?.length)
        ? values.emergencyContactName
        : null,
      email: Boolean(values.emergencyContactEmail?.length)
        ? values.emergencyContactEmail
        : null,
      relationship: Boolean(values.emergencyContactRelationSelect?.length)
        ? values.emergencyContactRelationSelect === "Other"
          ? values.emergencyContactRelationTextField
          : values.emergencyContactRelationSelect
        : null,
    };
    const contactMethods = formatEmergencyContactMethodToGateway(
      emergencyContact?.contacts || [],
      values
    );
    try {
      if (emergencyContact?.contact_id) {
        promises.push(
          updateEmergencyContact({
            data: {
              id: emergencyContact.contact_id,
              ...emergencyContactData,
            },
            correlationId: correlationId,
          })
        );
        contactMethods.forEach((eachContact) => {
          if (eachContact.id) {
            if (eachContact.reference && eachContact.region_code) {
              promises.push(
                updateEmergencyContactMethod({
                  obj: {
                    emergencyContactId: emergencyContact.contact_id as number,
                    data: eachContact,
                  },
                  correlationId: correlationId,
                })
              );
            } else {
              promises.push(
                deleteEmergencyContactMethod({
                  contactId: eachContact.id,
                  emergencyContactId: emergencyContact.contact_id as number,
                })
              );
            }
          } else {
            promises.push(
              createEmergencyContactMethod({
                obj: {
                  emergencyContactId: emergencyContact.contact_id as number,
                  data: eachContact,
                },
                correlationId: correlationId,
              })
            );
          }
        });
      } else {
        const emergencyContactResponse = await createEmergencyContact({
          data: emergencyContactData,
          correlationId: correlationId,
        });
        contactMethods.forEach((eachContact) => {
          if (eachContact.id) {
            promises.push(
              updateEmergencyContactMethod({
                obj: {
                  emergencyContactId: emergencyContactResponse.result.id,
                  data: eachContact,
                },
                correlationId: correlationId,
              })
            );
          } else {
            promises.push(
              createEmergencyContactMethod({
                obj: {
                  emergencyContactId: emergencyContactResponse.result.id,
                  data: eachContact,
                },
                correlationId: correlationId,
              })
            );
          }
        });
      }
      return Promise.all(promises);
    } catch (error) {
      return Promise.reject(error);
    }
  };

  const submitContactDetailsForm = (
    values: ContactDetailsFormType,
    hasInitialEmail = true,
    correlationId: string
  ) => {
    const promises = [];
    if (!hasInitialEmail && values.contactDetailsEmail?.length) {
      const travellerInfoData = {
        email: values.contactDetailsEmail || "",
      };
      if (travellerInfo?.id) {
        promises.push(
          updateTravellerInfo({
            data: { ...travellerInfoData },
            correlationId: correlationId,
          })
        );
      } else {
        promises.push(
          createTravellerInfo({
            data: { ...travellerInfoData },
            correlationId: correlationId,
          })
        );
      }
    }
    const travellerContactMethods = formatContactMethodToGateway(
      contactMethods || [],
      values
    );
    travellerContactMethods.forEach((eachContactMethod) => {
      if (eachContactMethod?.id) {
        if (eachContactMethod.reference && eachContactMethod.region_code) {
          promises.push(
            updateContactMethod({
              data: eachContactMethod,
              correlationId: correlationId,
            })
          );
        } else {
          promises.push(
            deleteContactMethod({ contactId: eachContactMethod.id })
          );
        }
      } else {
        promises.push(
          createContactMethod({
            data: eachContactMethod,
            correlationId: correlationId,
          })
        );
      }
    });

    const addressData = {
      country: Boolean(values.contactDetailsCountry?.label?.length)
        ? values.contactDetailsCountry?.label
        : null,
      post_code: Boolean(values.contactDetailsPostCode?.length)
        ? values.contactDetailsPostCode
        : null,
      state: Boolean(values.contactDetailsState?.length)
        ? values.contactDetailsState
        : null,
      street: Boolean(values.contactDetailsStreet?.length)
        ? values.contactDetailsStreet
        : null,
      suburb: Boolean(values.contactDetailsSuburb?.length)
        ? values.contactDetailsSuburb
        : null,
    };
    if (addresses?.id) {
      promises.push(
        updateAddress({
          data: { id: addresses.id, ...addressData },
          correlationId: correlationId,
        })
      );
    } else {
      promises.push(
        createAddress({
          data: { ...addressData },
          correlationId: correlationId,
        })
      );
    }

    return Promise.all(promises);
  };

  const submitPassportForm = (
    values: PassportFormType,
    correlationId: string
  ) => {
    return updatePassport({
      passport: {
        id: passport?.id ?? undefined,
        passport: values.passportStatus === Status.Yes ? "1" : "0",
        passport_nationality: Boolean(values.passportNationality?.label?.length)
          ? values.passportNationality?.label
          : null,
        passport_number: Boolean(values.passportNumber?.length)
          ? values.passportNumber
          : null,
        passport_issue_location: Boolean(values.passportIssueLocation?.length)
          ? values.passportIssueLocation
          : null,
        passport_issue_date: Boolean(values.passportIssueDate)
          ? values.passportIssueDate
          : null,
        passport_expiry_date: Boolean(values.passportExpiryDate)
          ? values.passportExpiryDate
          : null,
      },
      correlationId: correlationId,
    });
  };

  const submitPersonalDetailsForm = (
    values: PersonalDetailsFormType,
    correlationId: string
  ) => {
    const promises = [];
    promises.push(
      updateHealth({
        health: {
          medication: {
            id: health?.medication?.id ?? undefined,
            user_answer: Boolean(
              values.personalDetailsMedicationStatus === Status.Yes
            )
              ? "1"
              : "0",
            description: Boolean(
              values.personalDetailsMedicationDescription?.length
            )
              ? values.personalDetailsMedicationDescription?.toString()
              : null,
          },
          mental_health: {
            id: health?.mental_health?.id ?? undefined,
            user_answer: Boolean(
              values.personalDetailsMentalHealthStatus === Status.Yes
            )
              ? "1"
              : "0",
            description: Boolean(
              values.personalDetailsMentalHealthDescription?.length
            )
              ? values.personalDetailsMentalHealthDescription?.toString()
              : null,
          },
          physical_limitations: {
            id: health?.physical_limitations?.id ?? undefined,
            user_answer: Boolean(
              values.personalDetailsPhysicalLimitationsStatus === Status.Yes
            )
              ? "1"
              : "0",
            description: Boolean(
              values.personalDetailsPhysicalLimitationsDescription?.length
            )
              ? values.personalDetailsPhysicalLimitationsDescription?.toString()
              : null,
          },
          smoke: {
            id: health?.smoke?.id ?? undefined,
            description: Boolean(values.personalDetailsSmoke?.length)
              ? values.personalDetailsSmoke?.toString()
              : null,
          },
          // alcohol: {
          //   id: health?.alcohol?.id ?? undefined,
          //   description: Boolean(values.personalDetailsAlcohol?.length)
          //     ? values.personalDetailsAlcohol?.toString()
          //     : null,
          // },
          // illicit: {
          //   id: health?.illicit?.id ?? undefined,
          //   description: Boolean(values.personalDetailsIllicit?.length)
          //     ? values.personalDetailsIllicit?.toString()
          //     : null,
          // },
          diet: {
            id: health?.diet?.id ?? undefined,
            user_answer: Boolean(
              values.personalDetailsDietStatus === Status.Yes
            )
              ? "1"
              : "0",
            description: Boolean(values.personalDetailsDietDescription?.length)
              ? values.personalDetailsDietDescription?.toString()
              : null,
          },
          tattoos_piercings: {
            id: health?.tattoos_piercings?.id ?? undefined,
            user_answer: Boolean(
              values.personalDetailsTattoosPiercingsStatus === Status.Yes
            )
              ? "1"
              : "0",
            description: Boolean(
              values.personalDetailsTattoosPiercingsDescription?.length
            )
              ? values.personalDetailsTattoosPiercingsDescription?.toString()
              : null,
          },
          // covid_19_vaccination: {
          //   id: health?.covid_19_vaccination?.id ?? undefined,
          //   user_answer:
          //     values.personalDetailsCovidStatus === null
          //       ? null
          //       : Boolean(values.personalDetailsCovidStatus === Status.Yes)
          //       ? "1"
          //       : "0",
          //   description: Boolean(values.personalDetailsCovidDescription?.length)
          //     ? values.personalDetailsCovidDescription?.toString()
          //     : null,
          // },
        },
        correlationId: correlationId,
      })
    );
    promises.push(
      updateConvictions({
        convictions: {
          driving_offences: {
            id: convictions?.driving_offences?.id ?? undefined,
            date_occurred: Boolean(values.personalDetailsDrivingOffencesDate)
              ? values.personalDetailsDrivingOffencesDate
              : null,
            user_answer: Boolean(
              values.personalDetailsDrivingOffencesStatus === Status.Yes
            )
              ? "1"
              : "0",
            description: Boolean(
              values.personalDetailsDrivingOffencesDescription?.length
            )
              ? values.personalDetailsDrivingOffencesDescription?.toString()
              : null,
          },
          drink_driving: {
            id: convictions?.drink_driving?.id ?? undefined,
            date_occurred: Boolean(values.personalDetailsDrinkDrivingDate)
              ? values.personalDetailsDrinkDrivingDate
              : null,
            user_answer: Boolean(
              values.personalDetailsDrinkDrivingStatus === Status.Yes
            )
              ? "1"
              : "0",
            description: Boolean(
              values.personalDetailsDrinkDrivingDescription?.length
            )
              ? values.personalDetailsDrinkDrivingDescription?.toString()
              : null,
            bac: Boolean(values.personalDetailsDrinkDrivingBac)
              ? values.personalDetailsDrinkDrivingBac?.toString()
              : null,
          },
          criminal: {
            id: convictions?.criminal?.id ?? undefined,
            date_occurred: Boolean(values.personalDetailsCriminalDate)
              ? values.personalDetailsCriminalDate
              : null,
            user_answer: Boolean(
              values.personalDetailsCriminalStatus === Status.Yes
            )
              ? "1"
              : "0",
            description: Boolean(
              values.personalDetailsCriminalDescription?.length
            )
              ? values.personalDetailsCriminalDescription?.toString()
              : null,
          },
        },
        correlationId: correlationId,
      })
    );
    const travellerInfoData = {
      height: Boolean(values.personalDetailsHeight)
        ? values.personalDetailsHeight?.toString()
        : null,
      weight: Boolean(values.personalDetailsWeight)
        ? values.personalDetailsWeight?.toString()
        : null,
    };
    if (travellerInfo?.id) {
      promises.push(
        updateTravellerInfo({
          data: { ...travellerInfoData },
          correlationId: correlationId,
        })
      );
    } else {
      promises.push(
        createTravellerInfo({
          data: { ...travellerInfoData },
          correlationId: correlationId,
        })
      );
    }

    if (
      values.personalDetailsWorkingHolidayVisaStatus === Status.Yes &&
      !Boolean(workingHolidayVisa)
    ) {
      promises.push(
        createVisa({
          user_description: values.personalDetailsWorkingHolidayVisaDescription,
          issue_date: Boolean(values.personalDetailsWorkingHolidayVisaDate)
            ? values.personalDetailsWorkingHolidayVisaDate
            : null,
          type: "Working Holiday",
        })
      );
    }

    return Promise.all(promises);
  };

  const submitWorkingHolidayVisaForm = (
    values: WorkingHolidayVisaFormType,
    correlationId: string
  ) => {
    const promises = [];
    if (values.workingHolidayVisaStatus === Status.Yes) {
      promises.push(
        createVisa({
          user_description: values.workingHolidayVisaDescription,
          issue_date: Boolean(values.workingHolidayVisaDate)
            ? values.workingHolidayVisaDate
            : null,
          type: "Working Holiday",
        })
      );
    }
    return Promise.all(promises);
  };

  const submitDepartureDateForm = (values: DepartureDateFormType) => {
    const promises = [];

    promises.push(
      updateSignificantDate({
        date: values.departureDate ?? undefined,
        type: SignificantDateTypes.TENTATIVE_DEPARTURE,
      })
    );

    return Promise.all(promises);
  };

  useEffect(() => {
    return () => {
      cancelUploadRequest();

      cancelUpdateEmergencyContact();
      cancelCreateEmergencyContact();
      cancelUpdateEmergencyContactMethod();
      cancelCreateEmergencyContactMethod();

      cancelUpdateTravellerInfo();
      cancelCreateTravellerInfo();
      cancelUpdateContactMethod();
      cancelCreateContactMethod();
      cancelUpdateAddress();
      cancelCreateAddress();

      cancelUpdatePassport();

      cancelUpdateHealth();
      cancelUpdateConvictions();

      cancelDeleteContactMethod();
      cancelDeleteEMergencyContactMethod();
      cancelCreateVisa();
      cancelUpdateSignificantDate();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    submitProfileInfoForm,
    submitGeneralInfoForm,
    submitEmergencyContactForm,
    submitContactDetailsForm,
    submitPassportForm,
    submitGuardianDetailsForm,
    submitPersonalDetailsForm,
    submitWorkingHolidayVisaForm,
    submitDepartureDateForm,

    profilePictureUploadProgress: progress,
    isProfilePictureUploading: isPreparing || isUploading,
    isUpdatingGeneralInfo: isUpdatingTravellerInfo || isCreatingTravellerInfo,
    isUpdatingProfileInfo: isUpdatingTravellerInfo,
    isUpdatingContactDetails:
      isUpdatingAddress ||
      isCreatingAddress ||
      isUpdatingContactMethod ||
      isUpdatingTravellerInfo ||
      isCreatingContactMethod ||
      isDeletingContactMethod,
    isUpdatingEmergencyContact:
      isUpdatingEmergencyContact ||
      isCreatingEmergencyContact ||
      isUpdatingEmergencyContactMethod ||
      isCreatingEmergencyContactMethod ||
      isDeletingEmergencyContactMethod,
    isUpdatingGuardianDetails:
      isUpdatingEmergencyContact ||
      isCreatingEmergencyContact ||
      isUpdatingEmergencyContactMethod ||
      isCreatingEmergencyContactMethod ||
      isDeletingEmergencyContactMethod,
    isUpdatingPassport,
    isUpdatingPersonalDetails:
      isUpdatingTravellerInfo ||
      isUpdatingConvictions ||
      isUpdatingHealth ||
      isCreatingVisa,
    isUpdatingWorkingHolidayVisa: isCreatingVisa,
    isUpdatingDepartureDate: isUpdatingSignificantDate,
  };
};

export default usePersonalDetailsFormSubscription;
