import { TopLevelDialogNames } from "@/components/TopLevelDialogs";
import useTripsQuery, { Trip, TripStatus } from "@/fetch/gworld/useTripsQuery";
import { usePersonalDetailsQuery } from "@/fetch/profiles";
import { useAuth0 } from "@auth0/auth0-react";
import { useRouter } from "next/router";
import { ParsedUrlQueryInput } from "querystring";
import { useEffect, useMemo, useState } from "react";
import { Division } from "@/fetch/gworld";
import useBrowserSettings from "./useBrowserSettings";
import useCurrentTrip from "./useCurrentTrip";
import useDialog from "./useDialog";
import useFeatureFlags from "./useFeatureFlags";
import useFeatureTraits from "./useFeatureTraits";

const FINALISE_BOOKING_DATE_CONDITION = 1685577600; // 1 June 2023 00:00:00

const useLock = () => {
  const { data: personalDetailsData, isLoading: isPersonalDetailsLoading } =
    usePersonalDetailsQuery();

  const {
    data: trips,
    isLoading: isTripsLoading,
    isError: hasTripQueryError,
  } = useTripsQuery();
  const { currentTrip, setCurrentTrip } = useCurrentTrip();
  const [isCurrentTripLocked, setIsCurrentTripLocked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const router = useRouter();
  const { logout: auth0Logout } = useAuth0();
  const { isGappVersionOne, isGapp } = useBrowserSettings();
  const { openTopLevelDialog, onClose: onCloseDialog } = useDialog();

  const {
    flags: {
      paymentLock,
      eligibleForFullPaymentBanner,
      eligibleForPaymentPlanBanner,
    },
    isLoading: isFlagsLoading,
  } = useFeatureFlags();
  const {
    traits: { bannerText, bannerAction },
    isLoading: isLoadingTraits,
  } = useFeatureTraits();

  const isPaymentLockEnabled = Boolean(paymentLock);

  const [hasSuggestionBanner, setHasSuggestionBanner] = useState(
    Boolean(bannerText) && Boolean(bannerAction)
  );
  const closeSuggestionBanner = () => setHasSuggestionBanner(false);

  const isTripCancelling = currentTrip?.status === TripStatus.Cancelling;

  const isProfileSetup =
    typeof personalDetailsData?.traveller?.updated_profile === "number"
      ? Boolean(personalDetailsData?.traveller?.updated_profile)
      : null;

  const isSuperliteTrip = currentTrip?.division === Division.Superlite;

  const isTripLockedForUnderAgeWaitingApproval = (
    trip: Trip | undefined | null
  ) => {
    // since parent information now filled through gWorld, we want traveller to first
    // fill the parent info and then lock them, so if personal details are not filled yet
    // we let them to continue the setup process and then lock them

    const isLockedForUnderAgeWaitingApproval =
      trip?.status === TripStatus.UnderAgeWaitingApproval && isProfileSetup;
    return isLockedForUnderAgeWaitingApproval;
  };

  const isTripLocked = (trip: Trip | undefined | null) => {
    if (!trip) return true;

    const lockedTripsStatus = [
      TripStatus.Cancelled,
      TripStatus.OnHold,
      TripStatus.OnHoldFromSales,
      TripStatus.TripCancelled,
      TripStatus.Transfer,
    ];

    const isLockedForUnderAgeWaitingApproval =
      isTripLockedForUnderAgeWaitingApproval(trip);

    return (
      (trip?.status &&
        (lockedTripsStatus.includes(trip.status) ||
          isLockedForUnderAgeWaitingApproval)) ||
      false
    );
  };

  useEffect(() => {
    setHasSuggestionBanner(Boolean(bannerText) && Boolean(bannerAction));
  }, [bannerAction, bannerText]);

  useEffect(() => {
    const isLocked = isTripLocked(currentTrip);
    setIsCurrentTripLocked(isLocked);
  }, [currentTrip]);

  useEffect(() => {
    if (currentTrip?.division === Division.FIT) {
      focusOnNextWorkingTrip();
    }
  }, [currentTrip]);

  // const isProfileSetup = false; // uncomment to test the setup page

  const isSalesRegisterProcessed = Boolean(
    currentTrip?.sales_register_processed
  );

  /* Should redirect to the lock page when profile is set and trip is locked.
    Maybe user locked out of gworld because she did not complete the personal details yet,
    so she should see setup stepper page instead of lock page.
    */
  const shouldRedirectToLockPage = isCurrentTripLocked;

  const shouldRedirectToFinalisePage = useMemo(
    () =>
      currentTrip?.status !== TripStatus.Complete &&
      !shouldRedirectToLockPage &&
      !isSalesRegisterProcessed &&
      (currentTrip?._ca || 0) > FINALISE_BOOKING_DATE_CONDITION &&
      !isTripsLoading,
    [
      currentTrip?._ca,
      currentTrip?.status,
      isSalesRegisterProcessed,
      isTripsLoading,
      shouldRedirectToLockPage,
    ]
  );

  useEffect(() => {
    /* redirect to the right page based on different scenarios */
    if (isTripCancelling && !router.pathname.includes("/cancellation")) {
      router.replace({ pathname: "/cancellation" });
      return;
    } else if (
      shouldRedirectToFinalisePage &&
      !router.pathname.includes("/finalise")
    ) {
      router.replace({ pathname: "/finalise" });
      return;
    } else if (shouldRedirectToLockPage && !router.pathname.includes("/lock")) {
      router.replace({ pathname: "/lock", query: router.query });
      return;
    } else if (
      !shouldRedirectToLockPage &&
      !shouldRedirectToFinalisePage &&
      !isTripCancelling &&
      isProfileSetup === false &&
      !router.pathname.includes("/setup")
    ) {
      router.replace({ pathname: "/setup" });
      return;
    } else if (!shouldRedirectToLockPage && router.pathname.includes("/lock")) {
      router.replace({ pathname: "/" });
      return;
    } else if (
      isProfileSetup &&
      isPaymentLockEnabled &&
      !shouldRedirectToFinalisePage &&
      !isTripCancelling &&
      !shouldRedirectToLockPage &&
      !router.pathname.includes("/experience/payments")
    ) {
      router.replace({
        pathname: "/experience/payments/invoice/trip",
        query: router.query,
      });
    }
  }, [
    router.pathname,
    isPersonalDetailsLoading,
    isTripsLoading,
    isTripCancelling,
    router,
    isProfileSetup,
    shouldRedirectToLockPage,
    shouldRedirectToFinalisePage,
    isPaymentLockEnabled,
  ]);

  useEffect(() => {
    /* To prevent routing through pages when isPaymentLockEnabled is enabled */
    const handleRouteChange = (url: string) => {
      if (isPaymentLockEnabled && !url.includes("/experience/payments")) {
        if (url.includes("/experience/dashboard?tripChanged")) {
          /* Allow trip change by trip selector when current trip is locked 
             and lock page is rendering */
          return;
        }

        if (
          (!isProfileSetup && url.includes("/setup")) ||
          (isTripCancelling && url.includes("/cancellation")) ||
          (shouldRedirectToLockPage && url.includes("/lock")) ||
          (shouldRedirectToFinalisePage && url.includes("/finalise")) ||
          (!shouldRedirectToLockPage && url === "/") ||
          (!shouldRedirectToFinalisePage && url === "/")
        ) {
          /* Allow going to setup, cancellation, lock or / when it's needed */
          return;
        }
        throw `Access to "${url}" denied, payment lock is enabled.`;
      }
    };
    router.events.on("routeChangeStart", handleRouteChange);
    return () => {
      router.events.off("routeChangeStart", handleRouteChange);
    };
  }, [
    router.events,
    isProfileSetup,
    isPaymentLockEnabled,
    isTripCancelling,
    shouldRedirectToLockPage,
    shouldRedirectToFinalisePage,
  ]);

  useEffect(() => {
    /* To prevent routing through pages when profile is not setup */
    const handleRouteChange = (url: string) => {
      if (isProfileSetup === false && !url.includes("setup")) {
        if (
          (shouldRedirectToLockPage && url.includes("/lock")) ||
          (shouldRedirectToFinalisePage && url.includes("/finalise")) ||
          (!shouldRedirectToLockPage && url === "/") ||
          (!shouldRedirectToFinalisePage && url === "/")
        ) {
          return;
        }
        throw `Access to "${url}" denied, profile is not setup yet.`;
      }
    };
    router.events.on("routeChangeStart", handleRouteChange);
    return () => {
      router.events.off("routeChangeStart", handleRouteChange);
    };
  }, [
    router.events,
    isProfileSetup,
    shouldRedirectToLockPage,
    shouldRedirectToFinalisePage,
  ]);

  useEffect(() => {
    /* To prevent routing through pages when trip status is cancelling*/
    const handleRouteChange = (url: string) => {
      if (isTripCancelling && !url.includes("/cancellation")) {
        if (url.includes("/experience/dashboard?tripChanged")) {
          /* Allow trip change by trip selector when current trip is locked 
             and lock page is rendering */
          return;
        }
        throw "Access denied, please complete the cancellation flow.";
      }
    };
    router.events.on("routeChangeStart", handleRouteChange);
    return () => {
      router.events.off("routeChangeStart", handleRouteChange);
    };
  }, [router.events, isTripCancelling]);

  useEffect(() => {
    /* To prevent routing through pages when trip is locked (cancelled, on-hold, etc.)*/
    const handleRouteChange = (url: string) => {
      if (shouldRedirectToLockPage && !url.includes("lock")) {
        if (url.includes("/experience/dashboard?tripChanged")) {
          /* Allow trip change by trip selector when current trip is locked 
             and lock page is rendering */
          return;
        }

        throw `Access to "${url}" denied, you are locked out from gWorld.`;
      }
    };
    router.events.on("routeChangeStart", handleRouteChange);
    return () => {
      router.events.off("routeChangeStart", handleRouteChange);
    };
  }, [router.events, shouldRedirectToLockPage]);

  useEffect(() => {
    /* Handle the overlay loading based on pathname and status */
    if (
      (isProfileSetup === false &&
        !isTripCancelling &&
        !shouldRedirectToLockPage &&
        !shouldRedirectToFinalisePage &&
        !router.pathname.includes("/setup")) ||
      (shouldRedirectToLockPage && !router.pathname.includes("/lock")) ||
      (isTripCancelling && !router.pathname.includes("/cancellation")) ||
      (isPaymentLockEnabled &&
        isProfileSetup &&
        !isTripCancelling &&
        !shouldRedirectToFinalisePage &&
        !shouldRedirectToLockPage &&
        !router.pathname.includes("/experience/payments")) ||
      isPersonalDetailsLoading ||
      isTripsLoading ||
      isProfileSetup === null ||
      isFlagsLoading ||
      isLoadingTraits
    ) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [
    shouldRedirectToLockPage,
    isPersonalDetailsLoading,
    isProfileSetup,
    isTripCancelling,
    isTripsLoading,
    isFlagsLoading,
    isLoadingTraits,
    router.pathname,
    isPaymentLockEnabled,
    shouldRedirectToFinalisePage,
  ]);

  useEffect(() => {
    if (
      isGappVersionOne &&
      router.query.dialog !== TopLevelDialogNames.UpdateGapp
    ) {
      openTopLevelDialog(TopLevelDialogNames.UpdateGapp);
    }
    if (
      !isGappVersionOne &&
      router.query.dialog === TopLevelDialogNames.UpdateGapp
    ) {
      onCloseDialog();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isGappVersionOne, router.query.dialog]);

  useEffect(() => {
    if (hasTripQueryError) {
      const websiteUrl = "https://globalworkandtravel.com";
      console.log("logging out...");
      auth0Logout({
        logoutParams: {
          returnTo: isGapp ? websiteUrl : global.window?.location.origin,
        },
      });
    }
  }, [auth0Logout, hasTripQueryError]);

  const focusOnNextWorkingTrip = () => {
    const trip = trips?.find(
      (trip: Trip) =>
        !isTripLocked(trip) &&
        trip.status !== TripStatus.Cancelling &&
        trip.division !== Division.FIT
    );
    if (trip) {
      setCurrentTrip(trip);
    }
  };

  useEffect(() => {
    const getTripToFocus = (): Trip | undefined => {
      const tripIdQueryParam =
        Number((router.query as ParsedUrlQueryInput)?.tripId ?? 0) || null;
      const tripFromParam =
        tripIdQueryParam &&
        trips?.find((trip: Trip) => trip.id === tripIdQueryParam);

      if (tripFromParam) {
        return tripFromParam;
      }

      const selectedTrip =
        currentTrip && trips?.find((trip: Trip) => trip.id === currentTrip.id);

      if (
        selectedTrip &&
        !isTripLocked(selectedTrip) &&
        selectedTrip.division !== Division.FIT
      ) {
        return selectedTrip;
      }

      // If there is no previously selected trip or the selected trip is locked,
      // then then return first trip that is not locked
      const trip = trips?.find(
        (trip: Trip) => !isTripLocked(trip) && trip.division !== Division.FIT
      );
      if (trip) {
        return trip;
      }

      // Otherwise return first trip
      return trips?.[0];
    };

    if (trips?.length) {
      const nextTripToBeSelected = getTripToFocus();
      setCurrentTrip(nextTripToBeSelected);
    }
  }, [trips, currentTrip, setCurrentTrip, router.query]);

  // Used for disabling links in pages that takes the user outside of the current page
  const isAllowedToNavigate =
    isProfileSetup &&
    !isTripCancelling &&
    !isCurrentTripLocked &&
    !isPaymentLockEnabled &&
    !shouldRedirectToFinalisePage;

  return {
    isLoading,
    isProfileSetup,
    isTripCancelling,
    isCurrentTripLocked,
    isTripLocked,
    isTripLockedForUnderAgeWaitingApproval,
    isAllowedToNavigate,
    hasSuggestionBanner: hasSuggestionBanner && isAllowedToNavigate,
    closeSuggestionBanner,
    isPaymentLockEnabled,
    focusOnNextWorkingTrip,
    isSuperliteTrip,
  };
};

export default useLock;
