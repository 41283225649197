import { FC } from "react";
import dayjs from "dayjs";
import Tag from "@/components/Tag";
import Typography from "@/components/Typography";
import DotSeparator from "@/components/DotSeparator";
import { useResponsive } from "@/hooks";
import {
  RedeemRequestItem,
  GlobalDollarRewardItem,
  RedeemRequestStatuses,
  GlobalDollarRewardTypes,
} from "@/fetch/rewards";
import styles from "./RedeemCard.module.scss";

type Props = {
  redeem: RedeemRequestItem;
  reward?: GlobalDollarRewardItem;
};

const RedeemCard: FC<Props> = ({ redeem, reward }) => {
  const { isMobile } = useResponsive();
  const baseRewardImageUrl =
    "https://gwatco-res.cloudinary.com/image/upload/w_132/gwat.gworld/assets/";

  const rewardImage = {
    [GlobalDollarRewardTypes.AMAZON_GIFT_CARD]: isMobile
      ? "amazon-reward-mobile.png"
      : "amazon-reward.png",
    [GlobalDollarRewardTypes.APPLE_GIFT_CARD]: "apple-reward.png",
    [GlobalDollarRewardTypes.GOOGLE_GIFT_CARD]: "google-reward.png",
    [GlobalDollarRewardTypes.GLOBAL_VOUCHER]: isMobile
      ? //"global-reward-mobile.png"
        "Rewards/Global_Work_Travel.jpg"
      : "Rewards/Global_Work_Travel.jpg",
    [GlobalDollarRewardTypes.DEALSAWAY_VOUCHER]: isMobile
      ? //"tour-ticket-mobile.png"
        "Rewards/DealsAway.jpg"
      : "Rewards/DealsAway.jpg",
    // [GlobalDollarRewardTypes.GLOBAL_VOUCHER]: "Global_Work_Travel.jpg",
    // [GlobalDollarRewardTypes.DEALSAWAY_VOUCHER]: "DealsAway.jpg",
    [GlobalDollarRewardTypes.UBER_VOUCHER]: "Rewards/Uber.jpg",
    [GlobalDollarRewardTypes.UBER_EATS_VOUCHER]: "Rewards/UberEats.jpg",
    [GlobalDollarRewardTypes.AIRBNB_VOUCHER]: "Rewards/airbnb.jpg",
    [GlobalDollarRewardTypes.GLOBAL_ANIMAL_WELFARE_FUND_DONATION]:
      "Rewards/gwat.jpg",
  };

  const isVoucherReward =
    redeem.reward_type === GlobalDollarRewardTypes.GLOBAL_VOUCHER ||
    redeem.reward_type === GlobalDollarRewardTypes.DEALSAWAY_VOUCHER ||
    redeem.reward_type === GlobalDollarRewardTypes.AIRBNB_VOUCHER ||
    redeem.reward_type ===
      GlobalDollarRewardTypes.GLOBAL_ANIMAL_WELFARE_FUND_DONATION ||
    redeem.reward_type === GlobalDollarRewardTypes.UBER_EATS_VOUCHER ||
    redeem.reward_type === GlobalDollarRewardTypes.UBER_VOUCHER;
  const isDonationReward =
    redeem.reward_type ===
    GlobalDollarRewardTypes.GLOBAL_ANIMAL_WELFARE_FUND_DONATION;

  const redeemDescription = {
    [RedeemRequestStatuses.DECLINED]:
      "Unfortunately, your redemption request was declined and the amount has been credited back. Please choose another reward.",
    [RedeemRequestStatuses.GRANTED]: isVoucherReward
      ? "Congratulations! Your redemption request has been approved, and your voucher has been applied to your profile."
      : "Congratulations! Your redemption request has been approved, and your gift card has been emailed to you.",
    [RedeemRequestStatuses.PENDING]:
      "Thank you for submitting your redemption request. We will review it and get back to you within 5-7 working days.",
  };

  const redeemDate = dayjs(redeem._ca * 1000).format("DD MMM, YYYY");
  return (
    <div className={styles.container}>
      <div className={styles.imageContainer}>
        <img //eslint-disable-line @next/next/no-img-element
          src={`${baseRewardImageUrl}${rewardImage[redeem.reward_type]}`}
          alt={reward?.title}
          className={styles.image}
        />
        {!isVoucherReward && (
          <div className={styles.imageLabel}>
            <Typography className={styles.currency} color="text.contrast">
              {reward?.currency_symbol || ""}
            </Typography>
            <Typography className={styles.amount} color="text.contrast">
              {reward?.amount || ""}
            </Typography>
          </div>
        )}
        {isVoucherReward && (
          <div className={styles.voucherImageLabel}>
            <Typography className={styles.voucherAmount} color="text.contrast">
              {`${reward?.currency_symbol || ""}${reward?.amount || ""}`}
              <Typography className={styles.voucherText} color="text.contrast">
                &nbsp;Off
              </Typography>
            </Typography>
          </div>
        )}
      </div>
      <div className={styles.detailContainer}>
        <div className={styles.middleColumn}>
          <div className={styles.titleContainer}>
            <Typography variant="body1">{redeem.reward_title}</Typography>
          </div>
          <Typography color="text.secondary">
            {redeemDescription[redeem.status]}
          </Typography>
        </div>
        <div className={styles.rightContainer}>
          <Tag
            color={
              redeem.status === RedeemRequestStatuses.PENDING
                ? "warning"
                : redeem.status === RedeemRequestStatuses.DECLINED
                ? "error"
                : "success"
            }
            variant="filled"
            label={
              redeem.status === RedeemRequestStatuses.PENDING
                ? "Pending"
                : redeem.status === RedeemRequestStatuses.DECLINED
                ? "Declined"
                : "Redeemed"
            }
            size="small"
          />
          <Typography color="text.secondary">{redeemDate}</Typography>
        </div>
      </div>
    </div>
  );
};

export default RedeemCard;
