import { QueryKey, useQuery, UseQueryOptions } from "react-query";
import { GATEWAY_URL } from "./constants";
import useFetchWithAuth from "@/fetch/fetchWithAuth";
import { useCurrentTrip } from "@/hooks";

type ProductType = {
  title: string;
  description: string;
  link: string;
};

export type SocialMediaAssetType = {
  _id: string;
  post_id: number;
  title: string;
  avatar_url: string;
  user_name: string;
  small_image: string;
  medium_image: string;
  large_image: string;
  locality: string;
  country: string;
  products: Array<ProductType>;
  platform_link: string;
  source_url: string;
  approved: boolean;
  has_permission: boolean;
  categories: Array<string>;
};

type Result = Array<SocialMediaAssetType>;

type QueryError = {};

const useSocialMediaAssetsQuery = (
  options?: UseQueryOptions<Result, QueryError, Result, QueryKey>
) => {
  const { currentTrip } = useCurrentTrip();
  const tripId = currentTrip?.trip_id;
  const destination = currentTrip?.destination || "";
  const division = currentTrip?.division || "";
  const categoriesParam = new URLSearchParams();
  categoriesParam.append("categories", `${tripId}`);
  categoriesParam.append("categories", division);
  categoriesParam.append("categories", destination);
  categoriesParam.append("categories", "Generic");
  categoriesParam.append("min", "10");
  const url = `${GATEWAY_URL}/static-assets/social-media?${categoriesParam}`;
  const { fetchWithAuth } = useFetchWithAuth();
  const cacheKey = [url];

  return useQuery<Result, QueryError>(
    cacheKey,
    async () => {
      if (!tripId) throw new Error();

      const res = await fetchWithAuth<Result>(url);
      return res;
    },
    {
      enabled: !!tripId,
      ...options,
    }
  );
};
export default useSocialMediaAssetsQuery;
