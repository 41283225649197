import { FC, useState, useRef, useEffect } from "react";
import Card, { ClickableCard } from "@/components/Card";
import Typography from "@/components/Typography";
import Avatar from "@/components/Avatar";
import {
  useSocialMediaAssetsQuery,
  SocialMediaAssetType,
} from "@/fetch/websiteContents";
import {
  ArrowIcon,
  ChevronIcon,
  InstagramColoredIcon,
} from "@/components/Icon";
import { Swiper, SwiperSlide, useSwiper } from "swiper/react";
import Grid from "@/components/Grid";
import {
  useSwiperSlidesPerView,
  useOpenExternalURL,
  useTrackers,
  useResponsive,
} from "@/hooks";
import SwiperType from "swiper/types/swiper-class";
import { Navigation } from "swiper";
import { Skeleton } from "@/components/Loader";
import InstagramPostDialog from "./InstagramPostDialog";
import styles from "./InstagramPostsCard.module.scss";

const InstagramPostCard: FC<{ post: SocialMediaAssetType }> = ({ post }) => {
  const openExternalLink = useOpenExternalURL();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { track } = useTrackers();
  const openPost = () => {
    track("Instagram Post Clicked", {
      eventId: "instagram-post-clicked",
      instagramLink: post.platform_link,
      user: post.user_name,
      title: post.title,
      country: post.country,
    });
    // openExternalLink(post.platform_link);
    setIsOpen(true);
  };

  const decodedTitle = decodeURIComponent(post.title);
  return (
    <>
      <ClickableCard className={styles.postCard} onClick={openPost}>
        <div className={styles.postCardHeader}>
          <Avatar src={post.avatar_url} size="s" />
          <div className={styles.nameContainer}>
            <Typography className={styles.name} variant="body2">
              {post.user_name}
            </Typography>
            <Typography variant="caption">{post.country}</Typography>
          </div>
          <InstagramColoredIcon className={styles.instagramIcon} />
        </div>
        {/* eslint-disable-next-line @next/next/no-img-element */}
        <img
          alt={post.title}
          src={post.medium_image}
          className={styles.postImage}
        />
        <Typography className={styles.title} variant="subtitle2">
          {decodedTitle}
        </Typography>
      </ClickableCard>
      {isOpen && (
        <InstagramPostDialog post={post} onClose={() => setIsOpen(false)} />
      )}
    </>
  );
};

const InstagramPostsCard: FC = () => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [swiperObj, setSwiperObj] = useState<SwiperType | undefined>(undefined);
  const swiperRef = useRef<SwiperType | null>(null);
  const { slidesPerView } = useSwiperSlidesPerView({
    containerRef: containerRef,
    slideWidth: 212,
  });
  const { data: socialMediaAssetsData, isLoading: isLoadingMediaAssets } =
    useSocialMediaAssetsQuery();

  const { isMobile } = useResponsive();

  const hasSocialMediaPosts = Boolean(
    (socialMediaAssetsData || [])?.length > 0
  );

  useEffect(() => {
    if (hasSocialMediaPosts && !isLoadingMediaAssets && swiperObj) {
      swiperObj?.slideTo?.(0, 100);
    }
  }, [hasSocialMediaPosts, isLoadingMediaAssets, swiperObj]);

  return !isLoadingMediaAssets ? (
    <>
      {isMobile ? (
        <>
          {(socialMediaAssetsData?.length || 0) > 0 && (
            <Typography
              variant="h6"
              color="text.secondary"
              className={styles.socialTitle}
            >
              This could be you soon!
            </Typography>
          )}
          <div ref={containerRef}>
            <Swiper
              initialSlide={0}
              onSwiper={setSwiperObj}
              slidesPerView={slidesPerView}
              spaceBetween={16}
              slidesOffsetAfter={16}
              slidesOffsetBefore={16}
              modules={[Navigation]}
              className={styles.swiperContainer}
              navigation
              loop={true}
            >
              {socialMediaAssetsData?.map((each) => (
                <SwiperSlide key={each._id}>
                  <InstagramPostCard key={each._id} post={each} />
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </>
      ) : (
        <>
          {hasSocialMediaPosts &&
            !isLoadingMediaAssets &&
            (socialMediaAssetsData?.length || 0) > 0 && (
              <div>
                <div className={styles.titleSpace}>
                  <Typography
                    variant="h6"
                    color="text.secondary"
                    className={styles.socialTitle}
                  >
                    This could be you soon!
                  </Typography>

                  {(socialMediaAssetsData?.length ?? 0) > 3 && (
                    <div className={styles.buttonContainer}>
                      <button
                        className={styles.prevButton}
                        onClick={() => swiperRef.current?.slidePrev()}
                      >
                        <ChevronIcon className={styles.iconSize} dir="left" />
                      </button>
                      <button
                        className={styles.nextButton}
                        onClick={() => swiperRef.current?.slideNext()}
                      >
                        <ChevronIcon className={styles.iconSize} />
                      </button>
                    </div>
                  )}
                </div>
                <Swiper
                  initialSlide={0}
                  onSwiper={setSwiperObj}
                  slidesPerView={3}
                  spaceBetween={14}
                  modules={[Navigation]}
                  className={styles.swiperContainer}
                  onBeforeInit={(swiper) => {
                    swiperRef.current = swiper;
                  }}
                  loop={true}
                >
                  {socialMediaAssetsData?.map((each) => (
                    <SwiperSlide key={each._id}>
                      <InstagramPostCard key={each._id} post={each} />
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>
            )}
        </>
      )}
    </>
  ) : (
    <>
      <Skeleton variant="text" width="200px" className={styles.socialTitle} />
      <Skeleton
        variant="rectangular"
        height="300px"
        className={styles.cardRoot}
      />
    </>
  );
};

export default InstagramPostsCard;
