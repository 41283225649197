import { Duration } from "@/fetch/inventory";

const useParseTripDuration = (durations: Array<Duration>) => {
  let label = "";
  if (durations.length === 1) {
    label = durations?.[0]?.duration || "";
  } else {
    const timePeriods = durations.map(
      (each) => (each?.duration || "").split(" ")?.[1]
    );
    const timePeriod = timePeriods.find((each) => each === "Months")
      ? "Months"
      : timePeriods.find((each) => each === "Weeks")
      ? "Weeks"
      : "Days";
    const relatedDurations = durations.filter(
      (each) => (each?.duration || "").indexOf(timePeriod) > -1
    );
    if (relatedDurations.length === 1) {
      label = relatedDurations?.[0].duration || "";
    } else {
      const sortedDurations = [...relatedDurations].sort((first, second) => {
        const firstValue = Number((first?.duration || "").split(" ")?.[0]);
        const secondValue = Number((second?.duration || "").split(" ")?.[0]);
        return firstValue - secondValue;
      });
      label = `${(sortedDurations?.[0]?.duration || "").split(" ")?.[0]}-${
        (sortedDurations?.[sortedDurations.length - 1]?.duration || "").split(
          " "
        )?.[0]
      } ${timePeriod}`;
    }
  }

  return { label };
};

export default useParseTripDuration;
