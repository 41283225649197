import SvgIcon, { SvgIconProps } from "./SvgIcon";

const TourIcon: React.FC<SvgIconProps> = ({
  variant = "contained",
  ...props
}) => {
  return (
    <SvgIcon {...props}>
      {variant == "contained" ? (
        <path d="M3 22C2.44772 22 2 21.5523 2 21V17.6803C2 17.4658 2.06898 17.257 2.19674 17.0847L10.75 5.55L9.59523 3.99931C9.26617 3.55742 9.35702 2.93224 9.79778 2.60166C10.2403 2.26979 10.8686 2.35979 11.1996 2.80299L12 3.875L12.8004 2.80299C13.1314 2.35979 13.7597 2.26979 14.2022 2.60167C14.643 2.93224 14.7338 3.55742 14.4048 3.99931L13.25 5.55L21.8033 17.0847C21.931 17.257 22 17.4658 22 17.6803V21C22 21.5523 21.5523 22 21 22H3ZM8.225 20H15.775L12 14.725L8.225 20Z" />
      ) : (
        <path d="M3 22C2.44772 22 2 21.5523 2 21V17.6803C2 17.4658 2.06898 17.257 2.19674 17.0847L10.75 5.55L9.59523 3.99931C9.26617 3.55742 9.35702 2.93224 9.79778 2.60166C10.2403 2.26979 10.8686 2.35979 11.1996 2.80299L12 3.875L12.8004 2.80299C13.1314 2.35979 13.7597 2.26979 14.2022 2.60167C14.643 2.93224 14.7338 3.55742 14.4048 3.99931L13.25 5.55L21.8033 17.0847C21.931 17.257 22 17.4658 22 17.6803V21C22 21.5523 21.5523 22 21 22H3ZM12 7.225L4 18V20H7L12 13L17 20H20V18L12 7.225ZM9.45 20H14.55L12 16.45L9.45 20Z" />
      )}
    </SvgIcon>
  );
};

export default TourIcon;
