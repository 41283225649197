import { FC, useState } from "react";
import Dialog, {
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@/components/Dialog";
import Button from "@/components/Button";
import Typography from "@/components/Typography";
import { GlobalDollarColoredIcon } from "@/components/Icon";
import {
  GlobalDollarRewardItem,
  GlobalDollarRewardTypes,
  useCreateRedeemRequestMutation,
} from "@/fetch/rewards";
import { LinearProgress } from "@/components/Loader";
import {
  useTrackers,
  useSnackbar,
  useResponsive,
  useOpenExternalURL,
} from "@/hooks";
import styles from "./RedeemConfirmationDialog.module.scss";

type Props = {
  onClose: () => void;
  reward: GlobalDollarRewardItem;
  onRedeem: () => void;
};

const RedeemConfirmationDialog: FC<Props> = ({ onClose, onRedeem, reward }) => {
  const [showSuccess, setShowSuccess] = useState<boolean>(false);
  const { captureException, track } = useTrackers();
  const { enqueueSnackbar } = useSnackbar();
  const { isMobile } = useResponsive();
  const openExternalUrl = useOpenExternalURL();

  const {
    mutateAsync: createRedeemRequest,
    isLoading: isCreatingRedeemRequest,
  } = useCreateRedeemRequestMutation();

  const baseRewardImageUrl =
    "https://gwatco-res.cloudinary.com/image/upload/v1682238572/gwat.gworld/assets/";

  const rewardImage = {
    [GlobalDollarRewardTypes.AMAZON_GIFT_CARD]: isMobile
      ? "amazon-reward-mobile.png"
      : "amazon-reward.png",
    [GlobalDollarRewardTypes.APPLE_GIFT_CARD]: "apple-reward.png",
    [GlobalDollarRewardTypes.GOOGLE_GIFT_CARD]: "google-reward.png",
    [GlobalDollarRewardTypes.GLOBAL_VOUCHER]: isMobile
      ? //"global-reward-mobile.png"
        "Rewards/Global_Work_Travel.jpg"
      : "Rewards/Global_Work_Travel.jpg",
    [GlobalDollarRewardTypes.DEALSAWAY_VOUCHER]: isMobile
      ? //"tour-ticket-mobile.png"
        "Rewards/DealsAway.jpg"
      : "Rewards/DealsAway.jpg",
    [GlobalDollarRewardTypes.UBER_VOUCHER]: "Rewards/Uber.jpg",
    [GlobalDollarRewardTypes.UBER_EATS_VOUCHER]: "Rewards/UberEats.jpg",
    [GlobalDollarRewardTypes.AIRBNB_VOUCHER]: "Rewards/airbnb.jpg",
    [GlobalDollarRewardTypes.GLOBAL_ANIMAL_WELFARE_FUND_DONATION]:
      "Rewards/gwat.jpg",
  };

  const firstRewardText = {
    [GlobalDollarRewardTypes.AMAZON_GIFT_CARD]: "Get a",
    [GlobalDollarRewardTypes.APPLE_GIFT_CARD]: "You will get a",
    [GlobalDollarRewardTypes.GOOGLE_GIFT_CARD]: "You will get a",
    [GlobalDollarRewardTypes.GLOBAL_VOUCHER]: "Get",
    [GlobalDollarRewardTypes.DEALSAWAY_VOUCHER]: "Get",
    [GlobalDollarRewardTypes.UBER_VOUCHER]: "Get a ",
    [GlobalDollarRewardTypes.UBER_EATS_VOUCHER]: "Get a ",
    [GlobalDollarRewardTypes.AIRBNB_VOUCHER]: "Get a ",
    [GlobalDollarRewardTypes.GLOBAL_ANIMAL_WELFARE_FUND_DONATION]: "Donate ",
  };

  const openDealsAway = () => {
    openExternalUrl("https://dealsaway.com/");
  };

  const rewardText = {
    [GlobalDollarRewardTypes.AMAZON_GIFT_CARD]: "eGift Card for Amazon.",
    [GlobalDollarRewardTypes.APPLE_GIFT_CARD]: "Apple gift card",
    [GlobalDollarRewardTypes.GOOGLE_GIFT_CARD]: "Google Play gift card.",
    [GlobalDollarRewardTypes.DEALSAWAY_VOUCHER]: (
      <>
        off at{" "}
        <Typography
          component="a"
          onClick={() => openDealsAway()}
          className={styles.link}
        >
          DealsAway
        </Typography>{" "}
        with this voucher.
      </>
    ),
    [GlobalDollarRewardTypes.GLOBAL_VOUCHER]:
      "off at Global Work & Travel with this voucher.",
    [GlobalDollarRewardTypes.UBER_VOUCHER]: "eGift Card for Uber.",
    [GlobalDollarRewardTypes.UBER_EATS_VOUCHER]: "eGift Card for Uber Eats.",
    [GlobalDollarRewardTypes.AIRBNB_VOUCHER]: "eGift Card for Airbnb",
    [GlobalDollarRewardTypes.GLOBAL_ANIMAL_WELFARE_FUND_DONATION]:
      "to the Global Animal Welfare Fund.",
  };

  const isRewardVoucher =
    reward.type === GlobalDollarRewardTypes.GLOBAL_VOUCHER ||
    reward.type === GlobalDollarRewardTypes.DEALSAWAY_VOUCHER;

  const handleCreateRedeemRequest = async () => {
    try {
      await createRedeemRequest({
        global_dollar_reward_id: reward.id,
      });
      track("Redeem Request Submitted", {
        eventId: "redeem-request-submitted",
        globalDollarRewardId: reward.id,
        globalDollarAmount: reward.global_dollar_amount,
        rewardCurrency: reward.currency,
        rewardAmount: reward.amount,
      });
      onRedeem();
      setShowSuccess(true);
      enqueueSnackbar("Submitted successfully.", {
        variant: "success",
      });
    } catch (error) {
      console.error(error);
      captureException(error);
      enqueueSnackbar("Sorry, something went wrong!", {
        variant: "error",
      });
    }
  };

  const handleClose = () => {
    if (isCreatingRedeemRequest) return;
    onClose();
  };

  return (
    <Dialog open onClose={handleClose} classes={{ paper: styles.dialogPaper }}>
      {showSuccess ? (
        <>
          <DialogTitle hasCloseButton onClose={handleClose}></DialogTitle>
          <DialogContent className={styles.successContent}>
            <Typography variant="body1">
              We have received your request. We will get back to you within 7
              working days.
            </Typography>
            <Button
              onClick={handleClose}
              variant="contained"
              color="primary"
              className={styles.button}
            >
              Close
            </Button>
          </DialogContent>
        </>
      ) : (
        <>
          <DialogTitle onClose={handleClose}>Redeem a Reward</DialogTitle>
          {isCreatingRedeemRequest && <LinearProgress />}
          <DialogContent className={styles.content}>
            <Typography variant="h6">You are requesting a reward</Typography>
            <div className={styles.card}>
              <div className={styles.imageContainer}>
                <img //eslint-disable-line @next/next/no-img-element
                  src={`${baseRewardImageUrl}${rewardImage[reward.type]}`}
                  alt={reward?.title}
                  className={styles.image}
                />
                {!isRewardVoucher && (
                  <div className={styles.imageLabel}>
                    <Typography
                      className={styles.currency}
                      color="text.contrast"
                    >
                      {reward.currency_symbol}
                    </Typography>
                    <Typography className={styles.amount} color="text.contrast">
                      {reward.amount}
                    </Typography>
                  </div>
                )}
                {isRewardVoucher && (
                  <div className={styles.voucherImageLabel}>
                    <Typography
                      className={styles.voucherAmount}
                      color="text.contrast"
                    >
                      {`${reward.currency_symbol}${reward.amount}`}
                      <Typography
                        className={styles.voucherText}
                        color="text.contrast"
                      >
                        &nbsp;Off
                      </Typography>
                    </Typography>
                  </div>
                )}
              </div>
              <div className={styles.detailContainer}>
                <div className={styles.middleColumn}>
                  <Typography variant="body1">{reward.title}</Typography>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    className={styles.description}
                  >
                    {`${firstRewardText[reward.type]} ${
                      reward.currency_symbol
                    }${reward.amount} `}
                    {rewardText[reward.type]}
                  </Typography>
                </div>
                <div className={styles.rightContainer}>
                  <GlobalDollarColoredIcon className={styles.dollarIcon} />
                  <Typography variant="h6">
                    {`x${reward.global_dollar_amount}`}
                  </Typography>
                </div>
              </div>
            </div>
          </DialogContent>
          <DialogActions className={styles.actionContainer}>
            <Button
              onClick={handleClose}
              variant="outlined"
              color="info"
              disabled={isCreatingRedeemRequest}
            >
              Cancel
            </Button>
            <Button
              onClick={handleCreateRedeemRequest}
              color="primary"
              disabled={isCreatingRedeemRequest}
            >
              Redeem
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};

export default RedeemConfirmationDialog;
