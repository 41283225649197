import Card, { ClickableCard } from "@/components/Card";
import {
  PlanOption,
  useGeneratePlanMutation,
  useInvoiceQuery,
  usePlanOptionsQuery,
} from "@/fetch/payment";
import PageTitle from "@/components/PageTitle";
import Radio from "@/components/Radio";
import Typography from "@/components/Typography";
import { CircularProgress } from "@/components/Loader";
import {
  TwoColumnTemplate,
  RightSide,
  Main,
} from "@/components/TwoColumnTemplate";
import styles from "./PlanInstalments.module.scss";
import Tag from "@/components/Tag";
import Divider from "@/components/Divider";
import DatePicker from "@/components/DatePicker";
import TextField from "@/components/TextField";
import Button from "@/components/Button";
import Table from "@/components/DataTable";
import { useDialog, useTrackers } from "@/hooks";
import Result from "./Result";
import { useState } from "react";
import dayjs, { Dayjs } from "dayjs";
import PayDialog from "./PayDialog";

const getPeriodName = (option: PlanOption["option"]): string => {
  switch (option.unit) {
    case "week":
      switch (option.interval) {
        case 1:
          return "weekly";

        case 2:
          return "fortnightly";
      }

    case "month":
      return "monthly";

    default:
      return "";
  }
};

enum CurrentState {
  Loading,
  Success,
  Fail,
  Initial,
}

const PlanInstalments: React.FC<{
  onGoBack: () => void;
  planOptions: Array<PlanOption> | undefined;
  format: (amount: number) => string;
  currency: string | undefined;
  symbol: string | undefined;
  invoiceId: number | undefined;
}> = ({ onGoBack, planOptions, format, currency, symbol, invoiceId }) => {
  const [selectedPlanIndex, setSelectedPlanIndex] = useState(0);
  const selectedPlan = planOptions?.[selectedPlanIndex];
  const maxDate = dayjs().add(14, "d");
  const tomorrowDate = dayjs().add(1, "d");
  const [startDate, setStartDate] = useState<Dayjs>(tomorrowDate);
  const {
    isOpen,
    onClose,
    openDialog: openSelectCardDialog,
  } = useDialog("cards");
  const { mutateAsync: generatePlansMutate } = useGeneratePlanMutation();
  const { refetch: refetchPlans } = usePlanOptionsQuery({ invoiceId });
  const { refetch: refetchInvoice } = useInvoiceQuery(invoiceId);
  const [currentState, setCurrentState] = useState<CurrentState>(
    CurrentState.Initial
  );
  const { track } = useTrackers();

  const generatePlans = async (selectedCardId: number) => {
    try {
      setCurrentState(CurrentState.Loading);
      if (!selectedPlan || !invoiceId || !selectedCardId)
        throw new Error("Cannot generate plans");

      const { interval, unit, count } = selectedPlan.option;

      const options = {
        interval,
        unit,
        count,
        startDate: startDate.utc().startOf("day").unix(),
        invoiceId,
        cardId: selectedCardId,
      };
      await generatePlansMutate(options);
      track("Payment Plan Submitted", {
        eventId: "payment-plan-submitted",
        planOptions: options,
      });
      await refetchInvoice();
      await refetchPlans();
      setCurrentState(CurrentState.Success);
    } catch (e) {
      console.error(e);
      setCurrentState(CurrentState.Fail);
    }
  };

  return (
    <>
      <TwoColumnTemplate>
        <Main>
          <PageTitle
            visibleInMobile
            title="Pay in Instalments"
            onGoBack={
              currentState === CurrentState.Initial ? onGoBack : undefined
            }
          />

          <Card>
            {currentState === CurrentState.Fail && (
              <div className={styles.success}>
                <Result type="error" message="Something went wrong!" />
              </div>
            )}
            {currentState === CurrentState.Success && (
              <div className={styles.success}>
                <Result
                  subMessage="Your Trip Coordinator will be assigned & in touch very soon to get things started."
                  type="success"
                  message="You're on track for your adventure abroad and have unlocked extra gWorld features."
                />
              </div>
            )}
            {currentState === CurrentState.Loading && (
              <div className={styles.loading}>
                <CircularProgress />
                <Typography>Setting your payment instalments...</Typography>
              </div>
            )}
            {currentState === CurrentState.Initial && (
              <>
                <Typography variant="body1">Select a payment plan</Typography>

                <div className={styles.instalments}>
                  {planOptions?.map(({ option, instalments }, i) => (
                    <ClickableCard
                      outlined
                      key={i}
                      onClick={() => setSelectedPlanIndex(i)}
                    >
                      <Radio checked={i === selectedPlanIndex} />
                      <div className={styles.row}>
                        <div className={styles.count}>
                          <Typography
                            variant="body1"
                            className={styles.firstText}
                          >
                            Pay in {option.count} instalments of
                          </Typography>
                          {option.promotion > 0 && (
                            <>
                              {i === 0 ? (
                                <Tag
                                  className={styles.tag}
                                  color="success"
                                  variant="filled"
                                  label={`Save ${format(option.promotion)}`}
                                />
                              ) : (
                                <Typography variant="body1">
                                  Save {format(option.promotion)}
                                </Typography>
                              )}
                            </>
                          )}
                        </div>
                        <div>
                          <Typography component="div" variant="h6">
                            {format(instalments[0])}
                          </Typography>
                          <Typography
                            component="div"
                            variant="subtitle2"
                            color="text.secondary"
                          >
                            {getPeriodName(option)}
                          </Typography>
                        </div>
                      </div>
                    </ClickableCard>
                  ))}
                </div>
                <Divider />
                <div className={styles.dateSection}>
                  <div className={styles.right}>
                    <Typography>
                      When would you like to make your first payment?
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      Your first instalment should be made within 2 weeks.
                    </Typography>
                    <DatePicker
                      value={startDate}
                      onChange={(date: any) => {
                        setStartDate(date);
                      }}
                      maxDate={maxDate}
                      minDate={tomorrowDate}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </div>
                  <Table
                    className={styles.table}
                    rowsPerPage={6}
                    showPagination
                    rows={selectedPlan?.instalments || []}
                    key={selectedPlanIndex}
                    columns={[
                      {
                        headerName: "Due Date",
                        selector: (_, index) => {
                          if (!selectedPlan) return "";
                          const { interval, unit } = selectedPlan.option;
                          const date = startDate.add(interval * index, unit);
                          return date.format("ll");
                        },
                      },
                      {
                        headerName: "Amount",
                        selector: (instalment) => format(instalment),
                      },
                    ]}
                  />
                </div>
                <Button
                  onClick={() => openSelectCardDialog()}
                  className={styles.continueButton}
                >
                  Continue
                </Button>
              </>
            )}
          </Card>
        </Main>
        <RightSide></RightSide>
      </TwoColumnTemplate>

      <PayDialog
        open={isOpen}
        currency={currency}
        currencySymbol={symbol}
        onlyAddCardWithoutPayment={true}
        onCardSelect={async (cardId) => {
          generatePlans(cardId);
          onClose();
        }}
      />
    </>
  );
};

export default PlanInstalments;
