import { FC, useEffect, useRef, useState } from "react";
import { useInView } from "react-intersection-observer";
import cx from "classnames";
import Typography from "@/components/Typography";
import { Skeleton } from "@/components/Loader";
import Grid from "@/components/Grid";
import { GlobalDollarColoredIcon, ChevronIcon } from "@/components/Icon";
import { useResponsive, useSwiperSlidesPerView } from "@/hooks";
import Tooltip from "@/components/Tooltip";
import {
  useGlobalDollarBalanceQuery,
  useCreateRedeemRequestMutation,
  useGlobalDollarRewardsQuery,
  useTripCurrencyInformationQuery,
  useRedeemRequestListQuery,
  GlobalDollarRewardTypes,
} from "@/fetch/rewards";
import Button from "@/components/Button";
import Menu, { MenuItem } from "@/components/Menu";
import { ListItemText } from "@/components/List";
import Swiper, { Slider } from "@/components/Swiper";
import { RedeemableCard, RedeemCard } from ".";
import styles from "./ClaimRewardTab.module.scss";

const MINIMUM_VALUE_FOR_REDEEMING_GLOBAL_DOLLARS = 50;

const Currencies = ["aud", "usd", "gbp", "cad", "eur", "nzd"];

const ClaimRewardTab: FC = () => {
  const [selectedCurrency, setSelectedCurrency] = useState<string>("");
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(anchorEl);
  const handleClose = () => {
    setAnchorEl(null);
  };

  const {
    data: globalDollarBalanceData,
    isLoading: isLoadingGlobalDollarBalance,
    refetch: refetchGlobalDollarBalance,
  } = useGlobalDollarBalanceQuery();

  const {
    data: globalDollarRewardsListData,
    isLoading: isLoadingGlobalDollarReward,
  } = useGlobalDollarRewardsQuery();
  const { data: currencyInformation, isLoading: isLoadingCurrency } =
    useTripCurrencyInformationQuery();
  const {
    data: redeemRequestData,
    isLoading: isLoadingRedeemRequests,
    refetch: refetchRedeemRequests,
    fetchNextPage: fetchRedeemRequestsNextPage,
    hasNextPage: hasRedeemRequestsNextPage,
    isFetchingNextPage: isFetchingRedeemRequestsNextPage,
  } = useRedeemRequestListQuery();

  const {
    ref: redeemRequestsLoaderContainerRef,
    inView: isRedeemRequestsLoaderInView,
  } = useInView({
    threshold: 0,
  });

  const amountContainerRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  const { isMobile } = useResponsive();
  const { slidesPerView } = useSwiperSlidesPerView({
    containerRef,
    slideWidth: 200,
  });

  const amountContainerWidth = amountContainerRef.current?.offsetWidth || 1;

  useEffect(() => {
    if (!currencyInformation) return;
    const currency =
      currencyInformation.result.currencyName === "nzd"
        ? "aud"
        : currencyInformation.result.currencyName;
    setSelectedCurrency(currency);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currencyInformation?.result.currencyName]);

  useEffect(() => {
    if (
      !isRedeemRequestsLoaderInView ||
      !hasRedeemRequestsNextPage ||
      isLoadingRedeemRequests ||
      isFetchingRedeemRequestsNextPage
    )
      return;

    fetchRedeemRequestsNextPage();
  }, [
    fetchRedeemRequestsNextPage,
    hasRedeemRequestsNextPage,
    isFetchingRedeemRequestsNextPage,
    isRedeemRequestsLoaderInView,
    isLoadingRedeemRequests,
  ]);

  const globalDollarBalance = globalDollarBalanceData?.result.balance || 0;
  const globalDollarRewardsList = globalDollarRewardsListData?.result || [];

  const filteredGlobalDollarRewards = [...globalDollarRewardsList]
    .filter((each) => {
      if (selectedCurrency.toLocaleLowerCase() === "nzd") {
        if (
          each.type === GlobalDollarRewardTypes.GLOBAL_VOUCHER ||
          each.type === GlobalDollarRewardTypes.DEALSAWAY_VOUCHER
        ) {
          return each.currency.toLowerCase() === selectedCurrency.toLowerCase();
        } else {
          return each.currency.toLowerCase() === "aud";
        }
      } else {
        return each.currency.toLowerCase() === selectedCurrency.toLowerCase();
      }
    })
    .sort(
      (firstReward, secondReward) => firstReward.amount - secondReward.amount
    );
  const globalDollarRedeemRequestsList =
    redeemRequestData?.pages.flatMap((eachPage) => eachPage.result || []) || [];
  const totalCountRedeemRequests =
    redeemRequestData?.pages?.[0]?.totalCount || 0;

  const isRedeemingGlobalDollarLocked =
    globalDollarBalance < MINIMUM_VALUE_FOR_REDEEMING_GLOBAL_DOLLARS &&
    globalDollarRedeemRequestsList.length === 0;

  const onRedeem = () => {
    refetchGlobalDollarBalance();
    refetchRedeemRequests();
  };

  return (
    <div ref={containerRef}>
      <div className={styles.topRow}>
        <Typography variant="h5" className={styles.title}>
          Claim Your Rewards!
        </Typography>

        {isLoadingCurrency ? (
          <Skeleton
            width="80px"
            height="46px"
            variant="rectangular"
            className={styles.currencyButtonLoader}
          />
        ) : (
          <>
            <Button
              variant="text"
              color="info"
              onClick={(e) => setAnchorEl(e.currentTarget)}
              className={styles.currencyButton}
            >
              {selectedCurrency.toUpperCase()}{" "}
              <ChevronIcon dir="down" fontSize="inherit" />
            </Button>
            <Menu
              anchorEl={anchorEl}
              open={isMenuOpen}
              onClose={handleClose}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              classes={{ paper: styles.currencyMenu }}
            >
              <MenuItem disabled value="">
                <ListItemText aria-selected="true" primary="Select Currency" />
              </MenuItem>
              {Currencies.map((eachCurrency) => (
                <MenuItem
                  selected={
                    !!selectedCurrency && eachCurrency === selectedCurrency
                  }
                  key={eachCurrency}
                  onClick={() => {
                    setSelectedCurrency(eachCurrency);
                    handleClose();
                  }}
                >
                  <ListItemText
                    aria-selected="true"
                    primary={eachCurrency.toUpperCase()}
                  />
                </MenuItem>
              ))}
            </Menu>
          </>
        )}
      </div>
      <Typography
        variant="body1"
        color="text.secondary"
        className={styles.description}
      >
        Exchange your Global Dollars and receive your rewards.
      </Typography>
      {isLoadingGlobalDollarBalance ? (
        <Skeleton variant="rectangular" className={styles.balanceLoader} />
      ) : (
        // <Tooltip
        //   title="You need to reach 120 Global Dollars in order to receive a reward."
        //   disableHoverListener={!isRedeemingGlobalDollarLocked}
        // >
        <div className={styles.totalCard}>
          <Typography variant="body1" className={styles.totalLabel}>
            Total Rewards
          </Typography>
          <div
            style={{
              marginRight: isMobile
                ? 0
                : `calc(50% - ${amountContainerWidth / 2}px)`,
            }}
            className={styles.totalValue}
            ref={amountContainerRef}
          >
            <GlobalDollarColoredIcon className={styles.dollarIcon} />
            <Typography variant="h4" className={styles.balance}>
              {globalDollarBalance || 0}
            </Typography>
          </div>
        </div>
        // </Tooltip>
      )}
      {isLoadingCurrency ? (
        <Skeleton
          width="80px"
          height="46px"
          variant="rectangular"
          className={styles.currencyMobileButtonLoader}
        />
      ) : (
        <Button
          variant="text"
          color="info"
          onClick={(e) => setAnchorEl(e.currentTarget)}
          className={styles.currencyMobileButton}
        >
          {selectedCurrency.toUpperCase()}{" "}
          <ChevronIcon dir="down" fontSize="inherit" />
        </Button>
      )}
      {isMobile ? (
        <Swiper
          slidesPerPage={slidesPerView}
          spaceBetween={16}
          roundSlides={false}
          title=""
        >
          {isLoadingGlobalDollarReward
            ? [1, 2, 3].map((each) => (
                <Slider key={each}>
                  <Skeleton
                    width="192px"
                    height="348px"
                    className={styles.loadingReward}
                  />
                </Slider>
              ))
            : filteredGlobalDollarRewards.map((each) => (
                <Slider key={each.id}>
                  <RedeemableCard
                    reward={each}
                    isAffordable={
                      (each.global_dollar_amount || 0) <= globalDollarBalance &&
                      !isRedeemingGlobalDollarLocked
                    }
                    onRedeem={onRedeem}
                  />
                </Slider>
              ))}
        </Swiper>
      ) : (
        <Grid container columnSpacing="16px" rowSpacing="24px">
          {isLoadingGlobalDollarReward
            ? [1, 2, 3].map((each) => (
                <Grid key={each} item>
                  <Skeleton
                    width="192px"
                    height="348px"
                    className={styles.loadingReward}
                  />
                </Grid>
              ))
            : filteredGlobalDollarRewards.map((each) => (
                <Grid item key={each.id}>
                  <RedeemableCard
                    reward={each}
                    isAffordable={
                      (each.global_dollar_amount || 0) <= globalDollarBalance &&
                      !isRedeemingGlobalDollarLocked
                    }
                    onRedeem={onRedeem}
                  />
                </Grid>
              ))}
        </Grid>
      )}

      {globalDollarRedeemRequestsList.length > 0 && (
        <>
          <Typography variant="h5" className={styles.rewardTitle}>
            My Rewards
          </Typography>

          {globalDollarRedeemRequestsList.map((each) => (
            <RedeemCard
              key={each.id}
              redeem={each}
              reward={globalDollarRewardsList.find(
                (eachReward) => eachReward.id === each.global_dollar_reward_id
              )}
            />
          ))}

          {totalCountRedeemRequests > 0 && hasRedeemRequestsNextPage && (
            <div ref={redeemRequestsLoaderContainerRef}>
              {isFetchingRedeemRequestsNextPage && (
                <Skeleton
                  variant="rectangular"
                  className={styles.redeemRequestLoader}
                />
              )}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default ClaimRewardTab;
