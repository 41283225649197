import { FC, useState } from "react";
import Button from "@/components/Button";
import Typography from "@/components/Typography";
import { GlobalDollarColoredIcon } from "@/components/Icon";
import {
  GlobalDollarRewardItem,
  GlobalDollarRewardTypes,
} from "@/fetch/rewards";
import { useOpenExternalURL } from "@/hooks";
import RedeemConfirmationDialog from "./RedeemConfirmationDialog";
import styles from "./RedeemableCard.module.scss";

type Props = {
  reward: GlobalDollarRewardItem;
  isAffordable: boolean;
  onRedeem: () => void;
};

const RedeemableCard: FC<Props> = ({ reward, isAffordable, onRedeem }) => {
  const [openConfirmationDialog, setOpenConfirmationDialog] =
    useState<boolean>(false);

  const openExternalUrl = useOpenExternalURL();

  const baseRewardImageUrl =
    "https://gwatco-res.cloudinary.com/image/upload/w_132/gwat.gworld/assets/";

  const firstRewardText = {
    [GlobalDollarRewardTypes.AMAZON_GIFT_CARD]: "Get a ",
    [GlobalDollarRewardTypes.APPLE_GIFT_CARD]: "You will get a",
    [GlobalDollarRewardTypes.GOOGLE_GIFT_CARD]: "You will get a",
    [GlobalDollarRewardTypes.GLOBAL_VOUCHER]: "Get",
    [GlobalDollarRewardTypes.DEALSAWAY_VOUCHER]: "Get",
    [GlobalDollarRewardTypes.UBER_VOUCHER]: "Get a ",
    [GlobalDollarRewardTypes.UBER_EATS_VOUCHER]: "Get a ",
    [GlobalDollarRewardTypes.AIRBNB_VOUCHER]: "Get a ",
    [GlobalDollarRewardTypes.GLOBAL_ANIMAL_WELFARE_FUND_DONATION]: "Donate ",
  };

  const openDealsAway = () => {
    openExternalUrl("https://dealsaway.com/");
  };

  const rewardText = {
    [GlobalDollarRewardTypes.AMAZON_GIFT_CARD]: "eGift Card for Amazon.",
    [GlobalDollarRewardTypes.APPLE_GIFT_CARD]: "Apple gift card",
    [GlobalDollarRewardTypes.GOOGLE_GIFT_CARD]: "Google Play gift card.",
    [GlobalDollarRewardTypes.DEALSAWAY_VOUCHER]: (
      <>
        off at{" "}
        <Typography
          component="a"
          onClick={() => openDealsAway()}
          className={styles.link}
        >
          DealsAway
        </Typography>{" "}
        with this voucher.
      </>
    ),
    [GlobalDollarRewardTypes.GLOBAL_VOUCHER]:
      "off at Global Work & Travel with this voucher.",
    [GlobalDollarRewardTypes.UBER_VOUCHER]: "eGift Card for Uber.",
    [GlobalDollarRewardTypes.UBER_EATS_VOUCHER]: "eGift Card for Uber Eats.",
    [GlobalDollarRewardTypes.AIRBNB_VOUCHER]: "eGift Card for Airbnb",
    [GlobalDollarRewardTypes.GLOBAL_ANIMAL_WELFARE_FUND_DONATION]:
      "to the Global Animal Welfare Fund.",
  };

  const rewardImage = {
    [GlobalDollarRewardTypes.AMAZON_GIFT_CARD]: "amazon-reward.png",
    [GlobalDollarRewardTypes.APPLE_GIFT_CARD]: "apple-reward.png",
    [GlobalDollarRewardTypes.GOOGLE_GIFT_CARD]: "google-reward.png",
    [GlobalDollarRewardTypes.GLOBAL_VOUCHER]: "Rewards/Global_Work_Travel.jpg",
    [GlobalDollarRewardTypes.DEALSAWAY_VOUCHER]: "Rewards/DealsAway.jpg",
    [GlobalDollarRewardTypes.UBER_VOUCHER]: "Rewards/Uber.jpg",
    [GlobalDollarRewardTypes.UBER_EATS_VOUCHER]: "Rewards/UberEats.jpg",
    [GlobalDollarRewardTypes.AIRBNB_VOUCHER]: "Rewards/airbnb.jpg",
    [GlobalDollarRewardTypes.GLOBAL_ANIMAL_WELFARE_FUND_DONATION]:
      "Rewards/gwat.jpg",
  };

  const isRewardVoucher =
    reward.type === GlobalDollarRewardTypes.GLOBAL_VOUCHER ||
    reward.type === GlobalDollarRewardTypes.DEALSAWAY_VOUCHER ||
    reward.type === GlobalDollarRewardTypes.UBER_VOUCHER ||
    reward.type === GlobalDollarRewardTypes.UBER_EATS_VOUCHER ||
    reward.type === GlobalDollarRewardTypes.AIRBNB_VOUCHER ||
    reward.type === GlobalDollarRewardTypes.GLOBAL_ANIMAL_WELFARE_FUND_DONATION;

  const rewardLayout = {
    [GlobalDollarRewardTypes.AMAZON_GIFT_CARD]: "",
    [GlobalDollarRewardTypes.APPLE_GIFT_CARD]: "",
    [GlobalDollarRewardTypes.GOOGLE_GIFT_CARD]: "",
    [GlobalDollarRewardTypes.GLOBAL_VOUCHER]: "global",
    [GlobalDollarRewardTypes.DEALSAWAY_VOUCHER]: "dealsaway",
    [GlobalDollarRewardTypes.UBER_VOUCHER]: "uber",
    [GlobalDollarRewardTypes.UBER_EATS_VOUCHER]: "ubereats",
    [GlobalDollarRewardTypes.AIRBNB_VOUCHER]: "airbnb",
    [GlobalDollarRewardTypes.GLOBAL_ANIMAL_WELFARE_FUND_DONATION]: "gawf",
  };

  return (
    <>
      <div className={styles.container}>
        <div className={styles.imageContainer}>
          <img //eslint-disable-line @next/next/no-img-element
            src={`${baseRewardImageUrl}${rewardImage[reward.type]}`}
            alt={reward?.title}
            className={styles.image}
          />
          {isRewardVoucher && (
            <>
              <img //eslint-disable-line @next/next/no-img-element
                src={`/images/${
                  rewardLayout[reward.type]
                }-claimable-layout.svg`}
                alt={reward?.title}
                // className={styles.image}
                style={{ position: "absolute", left: 0 }}
              />
              <img //eslint-disable-line @next/next/no-img-element
                src={`/images/claimable-voucher-layout.svg`}
                alt={reward?.title}
                // className={styles.image}
                style={{ position: "absolute", right: 17 }}
              />
            </>
          )}

          {!isRewardVoucher && (
            <div className={styles.imageLabel}>
              <Typography className={styles.currency} color="text.contrast">
                {reward.currency_symbol}
              </Typography>
              <Typography className={styles.amount} color="text.contrast">
                {reward.amount}
              </Typography>
            </div>
          )}
          {isRewardVoucher && (
            <div className={styles.voucherImageLabel}>
              <Typography
                className={styles.voucherAmount}
                color="text.contrast"
              >
                {`${reward.currency_symbol}${reward.amount}`}
                <Typography
                  className={styles.voucherText}
                  color="text.contrast"
                >
                  &nbsp;
                </Typography>
              </Typography>
            </div>
          )}
        </div>
        <Typography variant="subtitle1" className={styles.name}>
          {reward.title}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {`${firstRewardText[reward.type]} ${reward.currency_symbol}${
            reward.amount
          } `}
          {rewardText[reward.type]}
        </Typography>
        <div className={styles.valueContainer}>
          <GlobalDollarColoredIcon className={styles.dollarIcon} />
          <Typography variant="h6">{`x${reward.global_dollar_amount}`}</Typography>
        </div>
        <Button
          onClick={() => setOpenConfirmationDialog(true)}
          className={styles.button}
          disabled={!reward.enabled || !isAffordable}
        >
          Redeem
        </Button>
      </div>
      {openConfirmationDialog && (
        <RedeemConfirmationDialog
          onClose={() => setOpenConfirmationDialog(false)}
          reward={reward}
          onRedeem={onRedeem}
        />
      )}
    </>
  );
};

export default RedeemableCard;
