import { QueryKey, useQuery, UseQueryOptions } from "react-query";
import { REWARD_ENDPOINT } from "./constants";
import useFetchWithAuth from "@/fetch/fetchWithAuth";
import { useCurrentTrip } from "@/hooks";

export enum GlobalDollarRewardTypes {
  APPLE_GIFT_CARD = "apple_gift_card",
  AMAZON_GIFT_CARD = "amazon_gift_card",
  GOOGLE_GIFT_CARD = "google_gift_card",
  GLOBAL_VOUCHER = "global_voucher",
  DEALSAWAY_VOUCHER = "dealsAway_voucher",
  UBER_VOUCHER = "uber_voucher",
  UBER_EATS_VOUCHER = "uber_eats_voucher",
  AIRBNB_VOUCHER = "airbnb_voucher",
  GLOBAL_ANIMAL_WELFARE_FUND_DONATION = "global_animal_welfare_donation",
}

export type GlobalDollarRewardItem = {
  id: number;
  title: string;
  type: GlobalDollarRewardTypes;
  global_dollar_amount: number;
  enabled: number;
  expiry_date: any;
  metadata: { [key: string]: string };
  amount: number;
  currency: string;
  currency_symbol: string;
  _ca: number;
  _cb: number;
  _lma: any;
  _lmb: any;
};

export type GlobalDollarRewardList = {
  result: Array<GlobalDollarRewardItem>;
  success: boolean;
};

type QueryError = {};

const useGlobalDollarRewardsQuery = (
  options?: UseQueryOptions<
    GlobalDollarRewardList,
    QueryError,
    GlobalDollarRewardList,
    QueryKey
  >
) => {
  const { currentTrip } = useCurrentTrip();
  const URL = `${REWARD_ENDPOINT}/global-dollar/reward`;
  const { fetchWithAuth } = useFetchWithAuth();
  const cacheKey = [URL];

  return useQuery<GlobalDollarRewardList, QueryError>(
    cacheKey,
    async () => {
      const res = await fetchWithAuth<GlobalDollarRewardList>(URL);
      return res;
    },
    {
      ...options,
    }
  );
};
export default useGlobalDollarRewardsQuery;
