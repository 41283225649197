import SvgIcon, { SvgIconProps } from "./SvgIcon";

const AccommodationIcon: React.FC<SvgIconProps> = ({
  variant = "contained",
  ...props
}) => {
  return (
    <SvgIcon {...props}>
      {variant == "contained" ? (
        <path d="M3 22V9.67412C3 9.04041 3.30033 8.4442 3.80954 8.067L10.8095 2.88182C11.5167 2.35797 12.4833 2.35797 13.1905 2.88182L20.1905 8.067C20.6997 8.4442 21 9.04041 21 9.67412V22H17.625V10.8889H6.375V22H3ZM8.625 19.7778H15.375V17.5556H8.625V19.7778ZM8.625 15.3333H15.375V13.1111H8.625V15.3333Z" />
      ) : (
        <path d="M3 22V9.67412C3 9.04041 3.30033 8.4442 3.80954 8.067L10.8095 2.88182C11.5167 2.35797 12.4833 2.35797 13.1905 2.88182L20.1905 8.067C20.6997 8.4442 21 9.04041 21 9.67412V22H18.75V9.77778L12 4.77778L5.25 9.77778V22H3ZM8.625 19.7778H15.375V17.5556H8.625V19.7778ZM8.625 15.3333H15.375V13.1111H8.625V15.3333ZM6.375 22V12.8889C6.375 11.7843 7.27043 10.8889 8.375 10.8889H15.625C16.7296 10.8889 17.625 11.7843 17.625 12.8889V22H6.375Z" />
      )}
    </SvgIcon>
  );
};

export default AccommodationIcon;
